import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import CascadeLink from '../components/CascadeLink'
import Layout from '../components/Layout'

// Just a quick preview page, only way to get to it is by SEO

export const PressLinkTemplate = ({
  content,
  description,
  tags,
  title,
  helmet,
  href
}) => {
  return (
    <section className="section">
      {helmet || ''}
      <h1>
        {title}
      </h1>
      <p>{description}</p>
      <CascadeLink
        analyticsTargetName='Read More'
        href={href}
      >
        Read More...
      </CascadeLink>
    </section>
  )
}

PressLinkTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const PressLink = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <PressLinkTemplate
        content={post.htmlAst}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
        href={post.frontmatter.href}
      />
    </Layout>
  )
}

PressLink.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default PressLink

export const pageQuery = graphql`
  query PressLinkByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      htmlAst
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        href
      }
    }
  }
`
